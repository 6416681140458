
import { ArticlesAPI, PublicationsAPI } from '@/api'
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import { Article } from './types'
import { PublicationFormat, PublicationFormatResult } from '@/global-types'
import { scrollTo, updateMathJax } from '@/utils/utils'
import ArticlePageDefault from './ArticlePageDefault.vue'
import ArticlePageHeader from './ArticlePageHeader.vue'
import ArticlePageSubheader from './ArticlePageSubheader.vue'
import ArticlePageBibInfo from './ArticlePageBibInfo.vue'
import ArticlePageClassifications from './ArticlePageClassifications.vue'
import ArticlePageCitations from './ArticlePageCitations.vue'
import ArticlePageReferences from './ArticlePageReferences.vue'
import ArticlePageReview from './ArticlePageReview.vue'
import ArticlePageContainer from './ArticlePageContainer.vue'
import InternalLinks from '@/components/InternalLinks.vue'
import ArticlePageRelated from './ArticlePageRelated.vue'
import { trackArticleCounter5 } from '@/counter5-tracker'

@Component({
  components: {
    ArticlePageDefault,
    ArticlePageHeader,
    ArticlePageSubheader,
    ArticlePageBibInfo,
    ArticlePageClassifications,
    ArticlePageCitations,
    ArticlePageReferences,
    ArticlePageReview,
    ArticlePageContainer,
    ArticlePageRelated,
    InternalLinks,
  },
})
export default class ArticlePageErrata extends Vue {
  @Ref() container!: HTMLElement

  @Prop({ required: true }) article!: Article
  @Prop({ required: false, default: false }) unsubbed!: boolean // if true, display the unsubscribed view of MathSciNet

  originalArticleId = 0
  originalArticle: Article | null = null
  loading = false

  allArticleIds: number[] = []
  allArticles: Article[] = []

  corrigendumArticleIds: number[] = []
  corrigendumArticles: Article[] = []
  // loadingCorrigendumArticle = false

  // corrigendumCitationFormats = {
  //   ams: '',
  //   bib: '',
  //   tex: '',
  // }
  corrigendumCitationFormats : PublicationFormatResult[] = []

  originalCitationFormats = {
    ams: '',
    bib: '',
    tex: '',
  }

  scrollTo = scrollTo

  //
  // WATCH
  //

  @Watch('article', { immediate: true })
  onChangeArticle() {
    this.processArticle()
  }

  //
  // HOOKS
  //

  mounted() {
    updateMathJax()
  }

  updated() {
    if (this.container && this.article) {
      this.scrollTo(`#errata-MR${this.article.paperId}`)
    }

    updateMathJax()
  }

  //
  // COMPUTED PROPERTIES
  //
  get internalAccess() {
    return this.$store.state.AppConfigModule.appConfig.internal
  }

  //
  // METHODS
  //

  extractTitle(text: string) {
    const lowercaseTitle = text.replace(/(\s|^)see|in|of|to\b|:/gi, '').trim()
    return lowercaseTitle[0].toUpperCase() + lowercaseTitle.slice(1) // Handles cases where a word was before the title
  }

  async processArticle() {
    if (this.article === null) {
      return
    }
    this.allArticleIds.push(this.article.paperId)
    this.allArticles.push(this.article)
    // Pull all the related articles
    this.loading = true
    for (const index in this.article.relation.relatedPapers) {
      const relatedPaperId = this.article.relation.relatedPapers[index]
      if (!this.allArticleIds.includes(relatedPaperId)) {
        const relatedArticle = this.unsubbed
          ? await ArticlesAPI.getArticlePublic(relatedPaperId)
          : await ArticlesAPI.getArticle(relatedPaperId)
        if (relatedArticle) {
          this.allArticleIds.push(relatedPaperId)
          this.allArticles.push(relatedArticle)
        }
      }
    }
    // Find the original article (it'll have "see erratum in" text)
    // const searchText = 'see erratum in'
    const searchText = 'see '
    const possibleOriginalPaper = this.allArticles.filter(x => x.relation.relationText.toLowerCase().includes(searchText))
    if (possibleOriginalPaper && possibleOriginalPaper.length > 0) {
      this.originalArticle = possibleOriginalPaper[0]
      this.originalArticleId = this.originalArticle.paperId
    } else {
      console.log(`Couldn't find matching text "${searchText}", using smallest MR Number as original instead`)
      this.originalArticle = this.article
      this.originalArticleId = this.article.paperId
    }
    this.originalCitationFormats = await this.getCitationFormats(this.originalArticleId)

    this.corrigendumArticleIds = this.allArticleIds.filter(x => x !== this.originalArticleId)
    this.corrigendumArticles = this.allArticles.filter(x => x.paperId !== this.originalArticleId)
    for (const index in this.corrigendumArticleIds) {
      this.corrigendumCitationFormats.push(await this.getCitationFormats(this.corrigendumArticleIds[index]))
    }
    this.loading = false

    const corrigendumReviewsExist = this.corrigendumArticles.some(x => x.review && x.review.trim().length > 0)
    const hasReview = (this.article.review && this.article.review.trim().length > 0) || corrigendumReviewsExist
    if (hasReview) {
      if (!this.unsubbed) {
        trackArticleCounter5(this.article)
      }
    }
  }

  /*
  async processArticle() {
    if (this.article) {
      const originalPaperId = Math.min(...this.article.relation.relatedPapers)
      // const corrigendumPaperId = Math.max(...this.article.relation.relatedPapers)

      this.originalArticleId = originalPaperId
      this.corrigendumArticleIds = this.article.relation.relatedPapers.filter(x => x !== originalPaperId)

      const badPaperIds : number[] = []
      if (this.article.paperId === originalPaperId) {
        this.originalArticle = this.article
        this.loading = true
        for (const index in this.corrigendumArticleIds) {
          const corrigendumPaperId = this.corrigendumArticleIds[index]
          const corrigendumArticle = this.unsubbed
            ? await ArticlesAPI.getArticlePublic(corrigendumPaperId)
            : await ArticlesAPI.getArticle(corrigendumPaperId)
          if (corrigendumArticle) {
            this.corrigendumArticles.push(corrigendumArticle)
            this.corrigendumCitationFormats.push(await this.getCitationFormats(corrigendumPaperId))
          } else {
            // This paperId doesn't have any other data, remove it.
            badPaperIds.push(corrigendumPaperId)
          }
        }
      } else if (this.corrigendumArticleIds.indexOf(this.article.paperId) > -1) {
        // this.corrigendumArticles.push(this.article)
        // this.corrigendumCitationFormats.push(await this.getCitationFormats(this.article.paperId))
        this.loading = true

        this.originalArticle = this.unsubbed
          ? await ArticlesAPI.getArticlePublic(originalPaperId)
          : await ArticlesAPI.getArticle(originalPaperId)

        // const otherCorrigendumArticleIds = this.corrigendumArticleIds.filter(x => x !== this.article.paperId)
        if (this.corrigendumArticleIds.length > 0) {
          for (const index in this.corrigendumArticleIds) {
            const corrigendumPaperId = this.corrigendumArticleIds[index]
            if (this.article.paperId === corrigendumPaperId) {
              // It's the original paper, no need to pull it again.
              this.corrigendumArticles.push(this.article)
              this.corrigendumCitationFormats.push(await this.getCitationFormats(this.article.paperId))
              continue
            }
            const corrigendumArticle = this.unsubbed
              ? await ArticlesAPI.getArticlePublic(corrigendumPaperId)
              : await ArticlesAPI.getArticle(corrigendumPaperId)
            if (corrigendumArticle) {
              this.corrigendumArticles.push(corrigendumArticle)
              this.corrigendumCitationFormats.push(await this.getCitationFormats(corrigendumPaperId))
            } else {
              // This paperId doesn't have any other data, remove it.
              badPaperIds.push(corrigendumPaperId)
            }
          }
        }
      }

      // remove paperIds with no associated article
      this.corrigendumArticleIds = this.corrigendumArticleIds.filter(x => badPaperIds.indexOf(x) === -1)

      this.loading = false

      this.originalCitationFormats = await this.getCitationFormats(this.originalArticleId)
      // this.corrigendumCitationFormats = await this.getCitationFormats(this.corrigendumArticleIds)

      const corrigendumReviewsExist = this.corrigendumArticles.some(x => x.review && x.review.trim().length > 0)
      // const hasReview = (this.article.review && this.article.review.trim().length > 0) || (this.corrigendumArticles?.review && this.corrigendumArticles.review.trim().length > 0)
      const hasReview = (this.article.review && this.article.review.trim().length > 0) || corrigendumReviewsExist

      if (hasReview) {
        if (!this.unsubbed) {
          trackArticleCounter5(this.article)
        }
      }
    }
  }
  */
  async getCitationFormats(articleId: number) {
    const results = await PublicationsAPI.getPublicationFormat(
      [PublicationFormat.AMS, PublicationFormat.BIB, PublicationFormat.TEX, PublicationFormat.END],
      [articleId]
    )

    return results[0]
  }
}
