
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Article, CitationFormat } from './types'
import ArticlePagePrevNext from './ArticlePagePrevNext.vue'
import ArticleSourceModal from '@/components/ArticleSourceModal.vue'
import { trackArticleCounter5 } from '@/counter5-tracker'
import { BModal } from 'bootstrap-vue'
import { AMSAccountAPI, UserListsAPI } from '@/api'
import { UserListModal } from '@/components/user-lists'
import { UserList } from '@/global-types'

interface userListSummary {
  id: string,
  count: number,
  included: boolean,
  label: string,
}

@Component({
  components: {
    ArticlePagePrevNext,
    ArticleSourceModal,
    UserListModal,
  },
})
export default class ArticlePageSubheader extends Vue {
  @Prop({ required: true }) article!: Article
  @Prop({ required: true }) unsubbed!: boolean // if true, display the unsubscribed view of MathSciNet
  @Prop({ required: true }) citationFormats!: CitationFormat
  @Prop({ default: '' }) title!: string

  //
  // REACTIVE PROPERTIES
  //
  amsLoggedIn = false
  listSummary: userListSummary[] = []
  userLists: UserList[] = []
  userListModalOpen = false
  showActions = false
  showArticleModal = false
  showUserListsModal = false
  showFormattingAndDownloads = false
  selectedFormat = 'bibtex' // Default to BibTeX, most used format
  dismissCountDown = 0
  showEditLists = false // Used to disable unfinished feature going to production

  formattingOptions = [
    // { value: 'pdf', text: 'PDF' }, // Removed PDF Download from modal
    { value: 'permalink', text: 'Permalink' },
    { value: 'amsref', text: 'AMSRef', preview: this.amsref },
    { value: 'bibtex', text: 'BibTeX', preview: this.bibtex },
    { value: 'endnote', text: 'EndNote', preview: this.endnote },
    { value: 'tex', text: 'TeX', preview: this.tex },
    // { value: 'tex', text: 'TeX', preview: this.tex }, // Doesn't look like it's complete
  ]

  mounted() {
    this.loadLocalStorage()
    this.checkIfAMSLoggedIn()
    this.userListsStorageUpdated()
  }

  loadLocalStorage() {
    const savedFormat = localStorage.getItem('preferred_citation_format_article')
    if (savedFormat) {
      this.selectedFormat = savedFormat
    }
  }

  saveLocalStorage() {
    localStorage.setItem('preferred_citation_format_article', this.selectedFormat)
  }

  @Watch('selectedFormat')
  selectedFormatUpdated() {
    this.saveLocalStorage()
  }

  //
  // COMPUTED PROPERTIES
  //
  get internalAccess() {
    return this.$store.state.AppConfigModule.appConfig.internal
  }

  get sourceUrl() {
    // This needs to be modified to handle cases where there are multiple sources...
    // if (this.article && this.article?.articleUrl) {
    //   return this.article.articleUrl
    // }
    // return ''
    return this.article?.articleUrl
  }

  get permalink() {
    return 'https://mathscinet.ams.org/mathscinet-getitem?mr=' + this.article.paperId
  }

  get bibtex() {
    if (this.citationFormats.bib) {
      return this.citationFormats.bib
    } else {
      return 'Bibtex not supported for this paper'
    }
  }

  get amsref() {
    if (this.citationFormats.ams) {
      return this.citationFormats.ams
    } else {
      return 'AMSRef not supported for this paper'
    }
  }

  get tex() {
    if (this.citationFormats.tex) {
      return this.citationFormats.tex
    } else {
      return 'TeX not supported for this paper'
    }
  }

  get issue() {
    return this.article.issue?.issue
  }

  get paging() {
    return this.article.pagings?.paging
  }

  get endnote() {
    if (this.citationFormats.end) {
      return this.citationFormats.end
    } else {
      return 'EndNote not supported for this paper'
    }
    // // Might be missing information
    // let formatted = ''
    // if (this.article === null) {
    //   return
    // }

    // if (this.article.authorsAndMentions) {
    //   for (const i in this.article.authorsAndMentions.authors) {
    //     formatted += `%A ${this.article.authorsAndMentions.authors[i].name}\n`
    //   }
    // }

    // if (this.article.titles) {
    //   formatted += `%T ${this.article.titles.title}\n`
    // }

    // if (this.issue && this.issue.journal) {
    //   formatted += `%J ${this.issue.journal.shortTitle}\n`
    //   formatted += `%V ${this.issue.volume}\n`
    //   formatted += `%D ${this.issue.pubYearInt}\n`
    //   formatted += `%N ${this.issue.issNum}\n`
    //   formatted += `%@ ${this.issue.issn}\n`
    // }
    // if (this.article.book && this.article.book.series && this.article.book.series.length > 0) {
    //   formatted += `%S ${this.article.book.series[0].shortTitle}\n`
    //   // formatted += `%V ${this.issue.volume}\n` //https://mrweb.ams.org/mathscinet/search/publdoc.html?pg4=MR&s4=1113265 has a Volume?!
    // }

    // if (this.article.romanText) {
    //   formatted += `%Z ${this.article.romanText.join('. ')}`
    // }

    // if (this.paging && this.paging.text) {
    //   formatted += `%P ${this.paging.text}\n`
    // }

    // if (this.article.paperId) {
    //   formatted += `%L MR${this.article.paperId}`
    // }
    // return formatted
  }

  get pdfUrl() {
    if (document.location.host.indexOf('8080') > 0) {
      return 'http://africau.edu/images/default/sample.pdf'
    }

    return '/mathscinet/pdf/' + this.article.paperId + '.pdf'
  }

  get selectedFormatLabel() {
    return this.formattingOptions.filter(x => x.value === this.selectedFormat)[0].text
  }

  get formatPreview() {
    switch (this.selectedFormat) {
      case 'permalink':
        return this.permalink
      case 'amsref':
        return this.amsref
      case 'endnote':
        return this.endnote
      case 'bibtex':
        return this.bibtex
      case 'tex':
        return this.tex
    }
    return ''
  }

  get isPrelim() {
    return this.itemType.toLowerCase() === 'prelim'
  }

  get itemType() {
    return this.article.itemType ? this.article.itemType.trim().toLowerCase() : ''
  }

  get redStatus() {
    const treatments = ['zero', 'kill', 'murd']
    const statuses = ['zero', 'kill', 'murd']
    return this.article.treatment === null || treatments.indexOf(this.article.treatment.toLowerCase()) > -1 || statuses.indexOf(this.article.details.status.toLowerCase()) > -1
  }

  onClickCitationHandler() {
    // this.selectedFormat = this.formattingOptions[1].value
    this.showActions = false
    this.showFormattingAndDownloads = true
  }

  onClickReviewPDFHandler() {
    this.selectedFormat = 'pdf'
    this.showFormattingAndDownloads = true
  }

  //
  // METHODS
  //

  downloadPDF() {
    // Used to get around BootstrapVue
    // NOTE: This will not download the file if it's from a different domain, which makes it difficult to test outside of production
    const downloader = document.createElement('a')
    downloader.href = this.pdfUrl
    downloader.download = 'MR' + this.article.paperId + '.pdf'
    downloader.target = '_blank'
    downloader.click()
  }

  copyCitation() {
    const copyText = document.getElementById('citationText') as HTMLInputElement
    if (copyText) {
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */
      navigator.clipboard.writeText(copyText.value)
      copyText.setSelectionRange(0, 0) /* Clear the highlighting from the text */

      // Notify that the text was copied
      this.dismissCountDown = 3
    }
  }

  countDownChanged(dismissCountDown: number): void {
    this.dismissCountDown = dismissCountDown
  }

  trackCounter() {
    if (!this.unsubbed) {
      trackArticleCounter5(this.article, `${window.location.origin}${this.pdfUrl}`)
    }
  }

  countAccess() {
    if (!this.unsubbed) {
      trackArticleCounter5(this.article)
    }
  }

  onClickLoginToEdit() {
    const url = window.location.href
    AMSAccountAPI.redirectToAMSLogin(url)
  }

  async checkIfAMSLoggedIn() {
    this.amsLoggedIn = await AMSAccountAPI.isLoggedIn()

    if (!this.amsLoggedIn) {
      // Could be a different user
      UserListsAPI.clearLocalStorage()
    } else {
      // Update the cached lists
      UserListsAPI.getLists()
    }
  }

  userListsStorageUpdated() {
    this.userLists = UserListsAPI.pullFromLocalStorage()
    const newListSummary: userListSummary[] = []
    for (const i in this.userLists) {
      const list = this.userLists[i]
      newListSummary.push({
        label: list.label,
        count: list.mrNumbers.length,
        included: list.mrNumbers.includes(this.article.paperId),
        id: list.id!,
      })
    }
    // Replace the old listSummary
    this.listSummary.splice(0, this.listSummary.length, ...newListSummary)
  }
}
